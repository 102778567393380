/* styles.css */

/* Page Container */
.pageContainer1 {
  width: 100vw;
  display: flex;
  min-height: 100vh; /* Use min-height to allow container to expand vertically */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: scroll; /* Add vertical scrollbar if content exceeds viewport height */
}
  
  /* Authentication Wrapper */
  .authWrapper1 {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    height: fit-content;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Form */
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .formTitle {
    font-size: 24px;
    color: #f50057; /* Adjust color as needed */
    margin-bottom: 20px;
  }
  
  /* Input Fields */
  .input {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    color: black;
  }
  
  .inputIcon {
    margin-right: 10px;
  }
  
  /* Checkbox for Mentor */
  .checkboxLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    color: black;
  }
  
  /* Disciplines Checklist */
  .disciplinesList {
    max-height: 20vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    color: black !important; 
  }

  .policyMessageMobile {
    font-size: 14px; /* Adjust the font size for mobile */
    text-align: center; /* Center-align the text on mobile */
    padding: 10px; /* Add some padding to improve spacing on mobile */
  }
  
  /* References Input Fields */
  .references {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    color:black;
  }

  .black-text {
    color: black !important;
  }
  
  /* Submit Button */
  .submitButton {
    margin-top: 20px;
  }
  
  /* Policy Message */
  .policyMessage {
    margin-top: 10px;
    text-align: center;
  }
  
  /* Toggle Button */
  .toggleButton {
    margin-top: 20px;
  }