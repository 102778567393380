/* Global Styles */
body {
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
  }
  
  /* Header Styles */
  .header {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  .header h1 {
    font-size: 36px;
    margin: 0;
    background-color: white;
  }
  
  /* Main Content Styles */
  .main {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .policy-title {
    font-size: 28px;
    margin-top: 20px;
    background-color: white;
    color: #333;
  }
  
  .last-updated {
    font-size: 14px;
    color: #666;
  }
  
  .policy-section {
    margin-top: 20px;
    background-color: white;
  }
  
  .section-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .policy-list {
    list-style-type: disc;
    padding-left: 20px;
  }

  .privacy-footer {
    background-color: white;
  }
  
  /* Footer Styles */
  .footer {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  /* Link Styles */
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .header h1 {
      font-size: 28px;
    }
    
    .policy-title {
      font-size: 24px;
    }
    
    .section-title {
      font-size: 20px;
    }
  }