/* CommendationSubmission.css */

/* Container */
.commendation-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  /* Heading */
  .commendation-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333333;
  }
  
  /* Form */
  .commendation-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  
  /* Label */
  .commendation-label {
    font-size: 18px;
    color: #333333;
  }
  
  /* Select */
  .commendation-select {
    font-size: 16px;
    padding: 10px;
    border: 2px solid #007bff;
    border-radius: 5px;
    width: 100%;
    max-width: 300px;
  }
  
  /* Textarea */
  .commendation-textarea {
    font-size: 16px;
    padding: 10px;
    border: 2px solid #007bff;
    border-radius: 5px;
    resize: vertical;
    width: 100%;
    max-width: 777px;
  }
  
  /* Button */
  .commendation-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 12px 24px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
    width: 100%;
    max-width: 300px;
  }
  
  .commendation-button:hover {
    background-color: #0056b3;
  }
  