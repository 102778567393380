.cybrary-promo-banner {
    background-color: #252e3f;
    color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
  
  .cybrary-promo-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .cybrary-promo-text {
    width: 50%;
  }
  
  .cybrary-promo-text h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .cybrary-promo-text p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  .cybrary-promo-text button {
    background-color: #f04a52;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cybrary-promo-text button:hover {
    background-color: #d32f36;
  }
  
  .cybrary-promo-image img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .cybrary-history,
  .cybrary-offerings,
  .cybrary-features,
  .cybrary-testimonials {
    margin-bottom: 40px;
  }
  
  .cybrary-history h3,
  .cybrary-offerings h3,
  .cybrary-features h3,
  .cybrary-testimonials h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .cybrary-history p,
  .cybrary-offerings p,
  .cybrary-features ul,
  .cybrary-testimonials p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .cybrary-features ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .cybrary-testimonials {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .testimonial {
    flex: 1;
    border: 1px solid #ffffff;
    padding: 20px;
    border-radius: 5px;
    background-color: #000000;
  }
  
  .testimonial p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .testimonial p:last-child {
    margin-top: 10px;
    font-style: italic;
    color: #888;
  }