.postListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.sortTabBarContainer {
  flex: 0 0 auto;
  display: flex;
  height: '1vw';
  width: 100%;
  align-items: center;
  justify-content: center; /* Updated: center the content horizontally */
  padding: 0; /* Updated: remove the padding */
}

.postsContainer {
  margin-top: 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
}






