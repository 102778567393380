.chatgpt-container {
    text-align: center;
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .chatgpt-heading {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .chatgpt-description {
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .chatgpt-subheading {
    font-size: 24px;
    color: #444;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .chatgpt-list {
    font-size: 18px;
    color: #333;
    list-style-type: disc;
    margin-left: 30px;
  }
  
  .chatgpt-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .chatgpt-link:hover {
    text-decoration: underline;
  }