/* Main Paper */
.mainPaper {
  padding: 20px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;
}

.mainPaper:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px solid #4a90e2; /* A contrasting border */
  border-radius: 20px;
  z-index: -1;
  animation: borderAnimate 3s infinite linear alternate;
}

@keyframes borderAnimate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.03); /* Slight pulsating effect */
  }
}

.mainPaper:hover {
  background-color: rgba(255, 255, 255, 0.98); /* A subtle change on hover */
  transform: translateY(-5px) scale(1.02); /* Lift and zoom on hover */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3); /* Enhanced shadow effect */
}

/* Add a shimmering effect on hover */
.mainPaper:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.95), transparent);
  animation: shimmer 3s infinite linear;
}

@keyframes shimmer {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(150%);
  }
}



/* List Paper */
.listPaper {
  padding: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.listPaper:hover {
  transform: scale(1.02);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.3); /* Enhanced shadow effect */
}

/* Title */
.title {
  font-size: 36px; /* Even larger and more prominent */
  margin-bottom: 30px;
  color: #4a90e2;
  text-transform: uppercase;
  letter-spacing: 4px; /* Bigger letter spacing */
  animation: colorChange 4s alternate infinite; /* Color-changing and shaking animation */
}

@keyframes colorChange {
  from {
    color: #4a90e2;
  }
  to {
    color: #ff6f61;
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px); /* Shakes left and right */
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
}

/* List Wrapper */
.listWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  border: 2px dashed #ddd; /* Dashed border */
  border-radius: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.listWrapper:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.3);
}

/* List Item */
.listItem {
  font-size: 28px; /* Larger and bolder text */
  color: #000000; /* Matches the title color */
  font-weight: 800; /* Even bolder */
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.listItem:hover {
  opacity: 1;
  transform: scale(1.05); /* Slight zoom in on hover */
}

/* Query Count */
.queryCount {
  font-size: 24px; /* Larger text */
  color: #000000; /* Matching the animation color */
  transition: transform 0.3s ease-in-out;
}

.queryCount:hover {
  transform: scale(1.1);
}

/* Loading Text */
.loadingText {
  font-size: 24px; /* Larger text */
  color: #777;
  text-align: center;
  margin-top: 30px;
  animation: pulse 1.5s infinite, colorChange 4s alternate infinite; /* Pulse and color-changing animation */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
