/* Define CSS variables for colors */

  
  /* Light mode styles (default) */
  .results-container {
    background-color: var(--background-light);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px var(--box-shadow-light);
    color: var(--text-light);
  }
  
  .results-heading {
    color: var(--text-light);
  }
  
  .result-item {
    margin: 20px 0;
    padding: 15px;
    background-color: #fff;
    border: 1px solid var(--border-light);
    border-radius: 5px;
    box-shadow: 0 0 5px var(--box-shadow-light);
    color: var(--text-light);
  }
  
  /* Dark mode styles */
  @media (prefers-color-scheme: dark) {
    :root {
      --background-light: #f7f7f7;
      --background-dark: #222;
      --text-light: #333;
      --text-dark: #fff;
      --border-light: #ddd;
      --border-dark: #444;
      --box-shadow-light: rgba(0, 0, 0, 0.2);
      --box-shadow-dark: rgba(255, 255, 255, 0.2);
    }
  
    .results-container {
      background-color: var(--background-dark);
      color: var(--text-dark);
      box-shadow: 0 0 10px var(--box-shadow-dark);
    }
  
    .results-heading {
      color: var(--text-dark);
    }
  
    .result-item {
      background-color: var(--background-light);
      border: 1px solid var(--border-dark);
      box-shadow: 0 0 5px var(--box-shadow-dark);
      color: var(--text-dark);
    }
  }