/* Mentors.css */

/* Container styles */
.mentors-container1 {
    max-width: 100vw;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  /* Header styles */
 
  
  /* Search bar styles */
  
  
  .button1 {
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    outline: none;
  }
  
  .button1:hover {
    background-color: #0056b3;
  }
  
  /* User card container styles */
  .user-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .user-card1:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .avatar1 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 18px;
    margin: 0;
  }
  
  /* Disciplines list styles */
  .disciplines1-list {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .discipline1 {
    font-size: 14px;
    margin-bottom: 6px;
  }
  
  /* Commendations button styles */
  .commendations-button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
    outline: none;
  }
  
  .commendations-button:hover {
    background-color: #45a049;
  }
  
  /* User profile popup styles */
  .user-profile-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .user-profile {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure the popup is above the background */
  }
  
  .close-profile-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
    outline: none;
  }
  
  .close-profile-button:hover {
    background-color: #0056b3;
  }
  
  .user-profile h2 {
    font-size: 20px;
    margin: 0 0 10px 0;
  }
  
  .user-profile p {
    font-size: 16px;
    margin: 0 0 10px 0;
  }
  
  /* Mentorship request styles */
  .request-mentorship-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
    outline: none;
  }
  
  .request-mentorship-button:hover {
    background-color: #0056b3;
  }

  .user-card-container2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -10px; /* Add negative margin to compensate for spacing */
  }
  
  /* User card styles */
  .user-card1 {
    height: fit-content;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    margin: 10px; /* Add margin to create spacing between cards */
  }