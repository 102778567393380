/* Global Styles With Variables and Base Animations */
:root {
  --primary-color: #ffffff; /* white for light theme */
  --secondary-color: #000000; /* black for dark theme */
  --text-color: #ffffff; /* white text for dark theme */
  --text-color-dark: #000000; /* black text for light theme */
  --accent-color: #30303057; /* light gray for accents */
  --transition-duration: 0.3s; /* consistent transition duration */
  --transition-easing: ease-in-out; /* consistent transition easing */
  
  /* New variables */
  --border-radius: 4px; /* border radius for buttons/cards */
  --shadow-color: rgba(0, 0, 0, 0.5); /* shadow color for depth effects */
  --shadow-light-color: rgba(255, 255, 255, 0.5); /* shadow color for 'light mode' */
  --hover-scale: 1.05; /* scale for hover transformation */
  --focus-ring-color: #7c36d8; /* color for focus ring on elements */
  --focus-ring-width: 3px; /* width of the focus ring */
  --card-background: #f8f8f8; /* background color for card elements */
  --modal-overlay-opacity: 0.85; /* opacity for modal overlays */
  --input-padding: 10px 15px; /* padding inside text inputs */
  --input-border-color: #ccc; /* border color for inputs */
  --input-border-width: 1px; /* width of the input border */
  --input-border-style: solid; /* style of the input border */
  --scrollbar-thumb-color: #6e6e6e; /* color for scrollbar thumb */
  --scrollbar-track-color: #e0e0e0; /* color for scrollbar track */
  --link-hover-decoration: underline; /* link decoration on hover */
}

/* ... (Rest of your CSS remains unchanged) */

/* Light Mode Specific Styles */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-family: 'Arial', sans-serif;
  transition: background-color var(--transition-duration), color var(--transition-duration);
  width: calc(100vw - 34px);
  overflow-x: hidden;
}


/* Remove Chrome's Search Input Styling */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input, textarea, select {
  padding: var(--input-padding);
  border: var(--input-border-width) var(--input-border-style) var(--input-border-color);
  border-radius: var(--border-radius);
  transition: border-color var(--transition-duration) var(--transition-easing);
}

input:focus, textarea:focus, select:focus {
  border-color: var(--focus-ring-color);
  outline: none;
  box-shadow: 0 0 0 var(--focus-ring-width) var(--focus-ring-color);
}

/* Scrollbar Styles for Webkit Browsers */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: var(--border-radius);
  border: 0px solid var(--scrollbar-track-color);
}

/* Responsive Design for Body */
@media only screen and (max-width: 768px) {
  body {
    width: 100vw;
    overflow-x: hidden;
  }
}

/* Font Family for Code Elements */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Headers Styles */


/* Link Styles and Hover Animation */
a {
  
  transition: color var(--transition-duration) var(--transition-easing);
  position: relative;
}

a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--accent-color);
  visibility: hidden;
  transform: scaleX(0);
  transition: all var(--transition-duration) var(--transition-easing);
}

a:hover::after, a:focus::after {
  visibility: visible;
  transform: scaleX(1);
}

/* Button Hover Effect */

button:hover {
  background-color: var(--accent-color); /* Change as needed */
  transform: translateY(-2px); /* Slight lift */
}

/* Fade-in Animation for Body on Load */
@keyframes fadeInBody {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  animation: fadeInBody var(--transition-duration) var(--transition-easing) forwards;
}

/* FadeInUp Animation for Sections on Scroll */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

section {
  opacity: 0; /* Start invisible */
  animation: fadeInUp 0.5s ease-out forwards; /* Animate into view */
  animation-delay: 0.2s; /* Delay to stagger the animations */
}

/* Reduced Motion Query for Users Who Prefer Less Animation */
@media (prefers-reduced-motion: reduce) {
  *, *::before, *::after {
    animation-duration: 0s !important;
    transition-duration: 0s !important;
    animation-iteration-count: 1 !important;
  }
}