/* CommendationDisplay.css */

/* Container */
.commendation-display-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Heading */
  .commendation-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* List */
  .commendation-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* List Item */
  .commendation-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
  }
  
  /* Sender */
  .commendation-sender {
    font-weight: bold;
    margin-right: 10px;
    color: #007bff;
  }
  
  /* Commendation Text */
  .commendation-text {
    font-size: 16px;
    color: #444;
  }