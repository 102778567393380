.app {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: #333; /* Dark background color */
  color: #fff; /* Light text color */
}

.feeds-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 100%;
  margin-top: 20px;
  padding-bottom: 10px;
}

.rss-feed-item {
  flex: 0 0 19%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 5px;
  background-color: #444; /* Darker background color */
  color: #fff; /* Light text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.rss-feed-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}