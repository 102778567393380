/* Container Styles */


/* Heading Styles */
h2 {
  font-size: 32px;
  color: #2c3e50; /* Updated color */
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

/* Search Bar Styles */
.search-bar {
  margin-bottom: 20px;
}

input[type="text"] {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border: 2px solid #9b59b6; /* Updated color */
  border-radius: 4px;
}

/* Exploit Result Styles */
.exploit-results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.exploit-result {
  padding: 20px;
  background-color: #ecf0f1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.exploit-result:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  background-color: #bdc3c7; /* Slight color change on hover */
}

.exploit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #34495e;
}

.date {
  font-weight: bold;
  font-size: 14px;
  color: #8e44ad; /* Updated color */
}

.exploit-details {
  margin-top: 15px;
  font-size: 16px;
  color: #2c3e50;
}

.feeling,
.name,
.steps,
.result,
.lesson-learned {
  margin-bottom: 15px;
  font-weight: bold;
}

.steps {
  margin-top: 10px;
}

.steps span {
  display: block;
  margin-bottom: 5px;
  font-weight: normal;
}

.steps span::before {
  content: "➤"; /* Updated step indicator */
  font-weight: bold;
  color: #9b59b6;
  margin-right: 5px;
}