

.fixed-conversations {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--button-bg-light);
  color: var(--button-text-light);
  padding: 12px;
  border-top: 2px solid var(--border-color-light);
  width: 100%;
  text-align: center;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
}

body.dark-mode {
  background-color: #222;
  color: #EDEDED;
}

body.dark-mode .fixed-conversations {
  background-color: var(--button-bg-dark);
  color: var(--button-text-dark);
  border-top-color: var(--border-color-dark);
}



button {
  background-color: var(--primary-color);
  color: #000000;
  padding: 10px 22px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}



.full-width-menu {
  position: absolute;
  bottom: calc(100% + 12px);
  left: 0;
  background-color: #fff;
  border: 1px solid var(--border-color-light);
  border-radius: 4px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 24px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.12);
}

.full-width-menu button {
  margin-right: 12px;
}

.chat-container {
  display: block;
  width: auto;
  height: 100vh;
  overflow: hidden;
}

/* Container for Mentor (true) Chat Popups */
.mentor-chat-popups-container {
  width: 50%;
  bottom: 0;
  right: 0; /* Fixed width for mentor chat popups on the right */
  height: 100%; /* Full height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 16px; /* Add padding for spacing */
  margin-left: auto; /* Push to the right */
}

/* Container for Non-Mentor Chat Popups */
.non-mentor-chat-popups-container {
  width: 50%; /* Fixed width for non-mentor chat popups on the left */
  height: 100%; /* Full height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 16px; /* Add padding for spacing */
}

.chat-popups-container {
  background-color: #fff;
  border: 1px solid var(--border-color-light);
  border-radius: 4px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: calc(100% + 10px); /* Position it above the button with a 10px spacing */
  left: 0;
  width: auto;
  height: 9vh;
  background-color: #fff;
  border-top: 1px solid #000000;
  z-index: 1000;
  display: flex;
  flex-wrap: wrap; /* Allow popups to wrap to the next row */
  justify-content: flex-start; /* Start from the left */
  align-items: center;
  padding: 0 20px; /* Ensure a higher z-index to be above other elements */
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.chat-popups {
  position: relative;
  bottom: calc(100% + 10vw);
  left: 0vw;
  width: auto;
  height: 5vh;
  background-color: #fff;
  border: 1px solid #e0e0e0; /* Softer border color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px; /* Rounded corners for a modern look */
  transition: all 0.3s ease; /* Smooth transitions for hover effects */
}

.chat-popups:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  transform: translateY(-2px); /* Slight upward movement on hover */
}

.chat-popups .chat-popup {
  margin-right: 12px; /* Slightly increased spacing for better visual separation */
  margin-bottom: 12px;
  background-color: #f7f7f7; /* Light background for the individual popups */
  padding: 5px 10px; /* Padding for each popup */
  border-radius: 6px; /* Consistent rounded corners */
  transition: background-color 0.3s ease; /* Smooth color transition on hover */
}

.chat-popups .chat-popup:hover {
  background-color: #e0e0e0; /* Darker shade on hover for individual popups */
}
  
  /* Style the chat-request buttons */
  .chat-request-button {
    background-color: var(--primary-color);
    color: #fff;
    padding: 8px 18px;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
  
  .chat-request-button:hover {
    background-color: var(--hover-color);
  }

  .chat-with-user {
    background-color: var(--button-bg-dark);
    border-radius: 4px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.16);
    position: absolute;
    bottom: calc(100% + 0vw);
    left: 0;
    width: 100vw;
    max-height: 50vw; /* Set the maximum height to 20vw */
    background-color: #000000;
    z-index: 1001;
    display: none;/* Add scrollbars when content exceeds the maximum height */
  }

  .chat-input {
    background-color: #f5f5f5;
    border: 1px solid var(--border-color-light);
    border-radius: 4px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    bottom: 0;
    z-index: 1002;
    display: flex;
    align-items: center;
  }
  
  .input-container {
    flex-grow: 1;
    margin-right: 8px;
  }
  
  textarea {
    width: 90%;
    max-width: 40vw;
    max-height: 50vw;
    padding: 8px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    resize: none;
    overflow-y: hidden; /* Disable vertical scrolling */
    word-wrap: break-word;
  }
  
  .send-button {
    background-color: var(--primary-color);
    color: #000000;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
  
  .send-button:hover {
    background-color: var(--hover-color);
  }
  
  /* Show the chat-with-user container when needed */
  .chat-messages-container {
    max-height: calc(20vw); /* Adjust the max-height for spacing */
    max-width: 100%;
    word-wrap: break-word;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .chat-messages-container.mobile {
    max-height: calc(80vw); /* Adjust the max-height for spacing */
    max-width: 100%;
    word-wrap: break-word;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .chat-with-user.mobile {
    background-color: var(--button-bg-dark);
    border-radius: 4px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.16);
    position: absolute;
    bottom: calc(100% + 0vw);
    left: 0;
    width: 100vw;
    max-height: 80vw; /* Set the maximum height to 20vw */
    background-color: #000000;
    z-index: 1001;
    overflow-y: hidden;
    display: none;/* Add scrollbars when content exceeds the maximum height */
  }
  
  /* Additional styles for hover effect on chat popups */
  .chat-popups:hover .chat-with-user {
    display: block;
    width: 40vw;
    height: auto;
  }
  
  .chat-popups:hover .chat-with-user.mobile {
    display: block;
    width: 60vw;
    height: 80vw;
  }

  .chat-input.mobile {
    background-color: #f5f5f5;
    border: 1px solid var(--border-color-light);
    border-radius: 4px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    bottom: 0vw;
    z-index: 1002;
    display: flex;
    align-items: center;
  }
  

.chat-message {
  margin: 4px 0;
}

@media (max-width: 400px) {
  /* Modify styles for small screens here */

  textarea {
    width: 100%;
    max-width: 40vw;
    max-height: 50vw;
    padding: 8px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    resize: none;
    overflow-y: hidden; /* Disable vertical scrolling */
    word-wrap: break-word;
  }

  .fixed-conversations {
    /* Adjust padding, font size, button size, etc. for small screens */
    padding: 8px;
    font-size: 14px; /* Adjust the font size for small screens */
    transition: all 0.3s ease;
    max-width: 100vw; /* Smooth transition for all properties */
  }

  .full-width-menu {
    /* Adjust padding and font size for small screens */
    padding: 8px 12px;
    font-size: 12px; /* Adjust the font size for small screens */
    transition: all 0.3s ease; /* Smooth transition for all properties */
    overflow: auto; /* Add scrollbars when content exceeds the maximum height */
    max-height: 80vh; /* Set a maximum height for the menu */
    position: absolute;
    bottom: calc(100% + 8px); /* Position above the fixed-conversations bar */
    left: 0;
    width: 100%;
  }

  .chat-popups-container {
    /* Adjust padding and font size for small screens */
    padding: 8px 12px;
    font-size: 14px; /* Adjust the font size for small screens */
    transition: all 0.3s ease; /* Smooth transition for all properties */
    overflow-y: auto; /* Add vertical scrollbars when content exceeds the height */
    max-height: 60vh; /* Set a maximum height for the chat popups container */
    position: relative;
    bottom: calc(100% + 30vw); /* Position above the fixed-conversations bar */
    left: 0;
    width: 100%;
  }

  .chat-input {
    background-color: #f5f5f5;
    border: 1px solid var(--border-color-light);
    border-radius: 4px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    bottom: 0;
    z-index: 1002;
    display: flex;
    align-items: center;
  }

  

  /* Add more styles specific to small screens as needed */
}
