/* Campbell.css */

/* Define styles for the container */
.campbell-container {
    margin-left: 20vw;
    margin-right: 20vw;
    margin-top: 7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}

  /* Define styles for the custom title */
  .campbell-title {
    font-size: 64px; /* Increase the font size */
    color: transparent; /* Make text transparent initially */
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 2;
  }
  
  .campbell-title h1 {
    animation: titleGlow 2s ease-in-out infinite alternate; /* Apply the glow animation */
    text-shadow: 0 0 10px black; /* Add a black shadow around the letters */
  }
  
  @keyframes titleGlow {
    0% {
      text-shadow: none; /* No shadow initially */
    }
    100% {
      text-shadow: 0 0 20px rgba(255, 204, 0, 0.5); /* Golden shadow when glowing */
    }
  } 
  
  
  /* Define styles for the header */
  .campbell-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    z-index: 2;
  }
  
  .campbell-header img {
    width: 250px; /* Increase the image size as needed */
    animation: glow 2s infinite alternate; /* Apply the glow animation */
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7); /* Golden shadow */
  }

  .campbell-top-date img {
    width: 250px; /* Increase the image size as needed */
    animation: glow 2s infinite alternate; /* Apply the glow animation */
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7); /* Golden shadow */

    z-index: 2;
  }

  .campbell-bottom-date img {
    width: 250px; /* Increase the image size as needed */
    animation: glow 2s infinite alternate; /* Apply the glow animation */
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7); /* Golden shadow */

    z-index: 2;
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0 0 20px rgba(255, 204, 0, 0.7); /* Initial shadow */
    }
    100% {
      box-shadow: 0 0 40px rgba(255, 204, 0, 1), 0 0 80px rgba(255, 204, 0, 1); /* Glowing shadow */
    }
  }
  
  .campbell h1 {
    font-size: 24px; /* Adjust the font size as needed */
    color: #333; /* Text color */
  }
  
  /* Define styles for the images */
  .campbell-images {
    display: column;
    justify-content: space-between;
    margin-bottom: 20px;
    z-index: 2;
  }
  
  .campbell-images img {
    margin-left: 1vw;
    width: 690px;
    height: 690px; /* Increase the image size as needed */
    animation: glow 2s infinite alternate; /* Apply the glow animation */
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7); /* Golden shadow */

    z-index: 2;
  }
  
  /* Define styles for the content */
  .campbell-content {
    font-size: 18px; /* Initial font size */
    color: transparent; /* Make text transparent initially */
    line-height: 1.5;
    animation: textBreathing 7s infinite alternate; /* Apply the breathing animation */
    z-index: 2; 
  }
  
  @keyframes textBreathing {
    0% {
      font-size: 18px; /* Initial font size */
      color: transparent; /* Initial transparency */
    }
    100% {
      font-size: 24px; /* Larger font size */
      color: red; /* Red color */
    }
}
.campbell-clock {
  margin-top: -900px;   
position: center;
    z-index: 0;
    animation: rotateClock 60s linear infinite; /* Rotate every 60 seconds (1 minute) */
  }
  
  @keyframes rotateClock {
    0% {
      transform:  rotate(0deg); /* Initial position and rotation */
    }
    100% {
      transform:  rotate(360deg); /* Final position and rotation */
    }
  }
  
  
  
  

  @media (max-width: 768px) {
    .campbell-title {
      font-size: 24px; /* Adjust the font size for mobile */
    }
  
    .campbell-header img {
      max-width: 99vw; /* Adjust the image size for mobile */
      height: auto; /* Maintain aspect ratio */
      box-shadow: 0 0 5px rgba(255, 204, 0, 0.7);
      margin: 0 10px; /* Add horizontal margin for spacing */
    }
    .campbell-top-date img {
      max-width: 99vw; /* Adjust the image size for mobile */
      height: auto; /* Maintain aspect ratio */
      box-shadow: 0 0 5px rgba(255, 204, 0, 0.7);
      margin: 0 10px; /* Add horizontal margin for spacing */
    }
    .campbell-bottom-date img {
      max-width: 99vw; /* Adjust the image size for mobile */
      height: auto; /* Maintain aspect ratio */
      box-shadow: 0 0 5px rgba(255, 204, 0, 0.7);
      margin: 0 10px; /* Add horizontal margin for spacing */
    }
  
    .campbell h1 {
      font-size: 18px; /* Adjust the font size for mobile */
    }
  
    .campbell-images img {
      margin: 1vw;
      max-width: 99vw; /* Make images responsive to container width */
      height: auto; /* Maintain aspect ratio */
      box-shadow: 0 0 5px rgba(255, 204, 0, 0.7);
    }
  
    .campbell-content {
      font-size: 14px; /* Adjust the font size for mobile */
    }
    
    .campbell-clock {
      height: 42vw;
      width: 42vw;
      z-index: 0;
    }
  }
  