.user-entries-container {
    max-width: 20vw;
    margin: 0 auto;
    margin-left: 5vw;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    overflow-x: wrap;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .slide {
    margin-top: 20px;
    padding: 20px;
    width: 20vw;
    margin-right: 0vw;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .entry-details {
    margin-bottom: 20px;
    overflow-x: scroll;
    max-width: fit-content;
  }
  
  strong {
    font-weight: bold;
    overflow-x: scroll;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    position: center;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  .navigation{
    position: center;
    margin-left: 5vw;
}

  @media screen and (max-width: 768px) {

    button {
        padding: 10px 20px;
        background-color: #007bff;
        position: center;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
      }

    .navigation{
        position: center;
        margin-left: 20vw;
    }

    .slide {
        margin-top: 20px;
        padding: 20px;
        width: 80vw;
        margin-bottom: 2vh;
        margin-right: 1vw;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
      }
}