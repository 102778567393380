/* Styling for IT Management Overview */

.it-management-overview {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  h1 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
    color: #0073e6;
  }
  
  .section {
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  ul, ol {
    padding-left: 20px;
    font-size: 16px;
    line-height: 1.5;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  strong {
    font-weight: bold;
    color: #0073e6;
  }
  
  a {
    text-decoration: underline;
    color: #9200e6;
    transition: color 1.333s;
  }
  
  
  
  /* Responsive Layout for Small Screens */
  @media (max-width: 600px) {
    .it-management-overview {
      padding: 10px;
    }
  
    h1 {
      font-size: 24px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    ul, ol {
      font-size: 14px;
    }
  }