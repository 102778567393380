/* CourseraPromoBanner.css */

.coursera-promo-banner {
  background-color: #f4f7fc;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.coursera-promo-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.coursera-promo-text {
  width: 50%;
}

.coursera-promo-text h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333; /* Dark text color */
}

.coursera-promo-text p {
  font-size: 18px;
  line-height: 1.6;
  color: #666; /* Dark text color */
}

.coursera-promo-text button {
  background-color: #00aaff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.coursera-promo-text button:hover {
  background-color: #0088cc;
}

.coursera-promo-image img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.coursera-offerings h3,
.coursera-testimonials h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333; /* Dark text color */
}

.coursera-offerings ul,
.coursera-testimonials p {
  font-size: 16px;
  line-height: 1.6;
  color: #666; /* Dark text color */
}

.coursera-offerings ul {
  list-style-type: disc;
  padding-left: 20px;
}

.coursera-testimonials {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.testimonial {
  flex: 1;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
}

.testimonial p {
  font-size: 16px;
  line-height: 1.6;
}

.testimonial p:last-child {
  margin-top: 10px;
  font-style: italic;
  color: #888;
}