
.exploit-background {
    background-image: url('../../assets/Project_Mayhem.gif'); /* Adjust the path to your GIF */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

.exploit-search-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .search-bar {
    margin-bottom: 20px;
    text-align: center;
  }
  
  input[type="text"] {
    width: 80vw;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .button-group {
    text-align: center;
  }
  
  .load-button {
    margin: 5px;
    padding: 8px 16px;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .load-button.selected {
    background-color: #0056b3;
  }
  
  .exploit-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .exploit-result {
    flex-basis: fit-content;
    margin: 10px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .exploit-header {
    display: flex;
    justify-content: space-between;
  }
  
  .username {
    font-weight: bold;
  }
  
  .date {
    color: #888;
    font-size: 14px;
  }
  
  .exploit-details {
    margin-top: 10px;
  }
  
  .os,
  .exploit-value,
  .language {
    font-weight: bold;
    color: #333;
  }
  
  .description {
    margin-top: 15px;
    color: #444;
  }