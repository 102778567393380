/* Content Container */

.mainPaper1 {
    margin-top: 1vh;
    justify-content: flex-start;
}
.content-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 0vw;
    margin-left: 0vw;
  }
  
  /* User Info Container */
  .user-info {
    flex: 1;
    padding: 3px;
    margin-top: 1vh;
    margin-left: 1vw;
    height: fit-content; /* You can adjust the height as needed */
    max-width: 17vw; /* Set a maximum width to limit the width */
    border-radius: 10px;
    /* Add a creative background color or background image to the user-info */
    /* background-image: url('your-user-info-background-image.jpg'); Example background image */
    /* Additional background properties can be applied here */
  }

  .user-info1 {
    flex: 1;
    padding: 3px;
    margin-top: 1vh;
    margin-left: 1vw;
    text-align: center;
    height: fit-content; /* You can adjust the height as needed */
    max-width: 17vw; /* Set a maximum width to limit the width */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Add a creative background color or background image to the user-info */
    background: linear-gradient(to bottom, #ffffff, #b9b9b9); /* Example gradient background */
    /* background-image: url('your-user-info-background-image.jpg'); Example background image */
    /* Additional background properties can be applied here */
  }

  .user-info2 {
    flex: 1;
    padding: 3px;
    margin-top: 1vh;
    margin-left: 1vw;
    height: fit-content; /* You can adjust the height as needed */
    max-width: 17vw; /* Set a maximum width to limit the width */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Add a creative background color or background image to the user-info */
    background: linear-gradient(to bottom, #2f00ff, #ff002b); /* Example gradient background */
    /* background-image: url('your-user-info-background-image.jpg'); Example background image */
    /* Additional background properties can be applied here */
  }

  
  
  /* Posts Container */
  .posts1 {
    width: 55vw;
    flex: 2;
    margin-top: -1vh;
    padding: 1vw;
    border-radius: 0px;
    /* Add a creative background color or background image to the posts */
     /* Example gradient background */
    /* background-image: url('your-posts-background-image.jpg'); Example background image */
    /* Additional background properties can be applied here */
  }
  
  /* GIF Container */
  .gif-container {
    display: flex-start;
    flex-direction: column;
    position: center;
    margin-left: 2.5vw;
    margin-top: 10px;
    padding: 20px;
    border-radius: 10px;
    /* Add a creative background color or background image to the GIF container */
    background: linear-gradient(to right, #33ccff, #3399ff); /* Example gradient background */
    /* background-image: url('your-gif-container-background-image.jpg'); Example background image */
    /* Additional background properties can be applied here */
  }

  .user-entries-slider {
    flex: 3; /* Allow user-entries-slider to occupy the same space as user-info */
    margin-left: 20px; /* Add margin to create space between posts and UserEntriesSlider */
    /* Add any other styling you want for the user-entries-slider section */
  }

  @media screen and (max-width: 768px) {
    .content-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Align elements to the left */
        max-width: 100vw;
    }

    .user-info,
    .user-info1,
    .user-info2 {
        position: center;
        max-width: 100%; /* Allow elements to take full width */
        margin-top: 10px; /* Add spacing between stacked elements */
    }

    .posts1 {
        width: 95vw;
        flex: 2;
        margin-left: 0;
        margin-right: 1vw;
        position: center;
        border-radius: 0px;
        /* Add a creative background color or background image to the posts */
         /* Example gradient background */
        /* background-image: url('your-posts-background-image.jpg'); Example background image */
        /* Additional background properties can be applied here */
      }

    .mobile-entries-slider {
        margin-left: 0;
        max-width: 100%;
    }

    .gif-container {
        position: center;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
        /* Add a creative background color or background image to the GIF container */
        background: linear-gradient(to right, #33ccff, #3399ff); /* Example gradient background */
        /* background-image: url('your-gif-container-background-image.jpg'); Example background image */
        /* Additional background properties can be applied here */
      }
}