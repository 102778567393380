/* AboutYou.module.css */

/* Container styles */
.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

/* Heading styles */
.heading {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.subheading {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #555;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333; /* Text color remains black */
}

/* Button styles */
.button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

/* Social media input styles */
.social-label {
  font-weight: bold;
  color: #333;
}

/* Customize the appearance of each social media input as needed */
/* For example, LinkedIn input */
.social-input {
  background-color: #0077b5;
  color: #fff;
  font-weight: bold;
  border: none;
  padding: 12px;
  border-radius: 5px;
}

/* Light mode */
body.light-mode {
  background-color: #f5f5f5;
  color: #333;
}

/* Dark mode */
body.dark-mode {
  background-color: #222;
  color: #333; /* Text color remains black */
}

/* Toggle class for elements that should change between light and dark modes */
.light-mode .button {
  background-color: #007bff;
}

.light-mode .button:hover {
  background-color: #0056b3;
}

.light-mode .social-input {
  background-color: #0077b5;
}