.postCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

/* Stylish button with dark mode support */

.postCard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.votesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-right: 1px solid #ccc;
}

.votesWrapper > * {
  margin-bottom: 8px;
}

.title {
  margin-bottom: 4px;
}

.thumbnailWrapper {
  flex: 0 0 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnailWrapper .thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.postInfoWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.postInfoWrapper > *:not(:last-child) {
  margin-bottom: 8px;
}

.bottomBtns {
  display: flex;
  align-items: center;
}

.commentsBtn {
  margin-right: 8px;
}

.textSubmission {
  color: #555;
  margin-top: 8px;
}

.userAndDate {
  color: #888;
}

