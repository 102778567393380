.homepage {
    display: flex;
  }
  
  .feedAndPosts {
    display: flex;
    flex-grow: 1;
  }
  
  .feedContainer {
    width: 33vw;
  }
  
  .postsContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }