/* supportPage.css */

.support-page {
    display: flex;
    height: 100vh;
  }
  
  .left-container {
    flex: 1;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .discord-link {
    text-decoration: none;
  }
  
  .discord-icon {
    width: 300px;
    height: 300px;
  }
  
  .right-container {
    flex: 1;
    background-color: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  h2 {
    color: #000;
    margin-bottom: 20px;
  }
  
  .form {
    width: 80%;
    max-width: 400px;
  }
  
  .form-field {
    margin-bottom: 20px;
  }
  
  .label {
    color: #000;
  }
  
  
  .textarea-field {
    width: 80%;
    height: 100px;
    padding: 10px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
  }