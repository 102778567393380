.app {
  height: 100vh; /* Set the height of the app container to the height of the browser window */
  display: flex;
  align-items: flex-start;
}

.feeds-container-home {
  background-color: transparent;
  height: 100%;
  overflow-y: auto;
}

.rss-feed-item-combined {
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px; /* Add border radius for a rounded corner effect */
}

.loading-indicator {
  text-align: center;
  margin-top: 20px;
}

.link-container {
  margin-bottom: 2px; /* Apply a 2px margin to the link container below */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow to each link container */
}

.link-container a {
  display: block;
  padding: 5px;
  color: black;
  text-decoration: none;
}

/* Add a scrollbar to the feeds container */
.feeds-container::-webkit-scrollbar {
  width: 8px;
}

.feeds-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.feeds-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.feeds-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.shadowed-container {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.shadowed-container:hover {
  transform: scale(1.05);
}

.shadowed-container a {
  color: #333333;
  text-decoration: none;
}

.shadowed-container a:hover {
  text-decoration: underline;
}