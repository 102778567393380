/* Custom styles for the MentorForm component */

/* Container styling */
.mentor-form-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Styling for the "No" button when isMentor is true */
  .no-button {
    background-color: #ff5722;
  }
  
  /* Styling for the "Yes" button when isMentor is false */
  .yes-button {
    background-color: #4caf50;
  }
  
  /* Styling for checkboxes and labels */
  .disciplines-list {
    margin-top: 20px;
  }
  
  /* Styling for reference inputs */
  .input {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  .reference-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Styling for remove and add reference buttons */
  .remove-button,
  .add-reference-button {
    margin-left: 10px;
    padding: 8px 16px;
    background-color: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-reference-button {
    background-color: #3498db;
  }
  