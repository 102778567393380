/* Default styles for desktop */
.mentorship-guide {
  font-family: Arial, sans-serif;
  margin: 20px auto;
  max-width: 800px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

nav li {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  transition: background-color 0.3s ease;
}

nav li.active {
  background-color: #007BFF;
  color: white;
  border-radius: 4px;
}

.section-content {
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

nav li:hover {
  background-color: #007BFF;
  color: white;
  transition: background-color 0.3s ease;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .mentorship-guide {
    padding: 10px;
    max-width: 100%;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav li {
    padding: 10px;
    font-size: 14px;
  }

  .section-content {
    padding: 10px;
  }
}