.ioc-search-container {
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5; /* Light background color */
  color: #333; /* Default text color */
}

.ioc-results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.ioc-result {
  border: 1px solid #ddd;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: transform 0.2s, box-shadow 0.2s;
  margin-bottom: 20px; /* Separation between results */
}

.ioc-result:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ioc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.ioc-details {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 10px; /* Separation between query type and query result */
}

.ioc-description {
  overflow: hidden;
  max-width: 100%;
  margin-top: 10px; /* Separation between query result and description */
}

.ioc-description code {
  display: block; /* Ensures "Description" and description text appear on separate lines */
}

.ioc-scrollable {
  overflow: auto;
  max-width: 100%;
  max-height: 150px;
  white-space: pre-wrap;
}

.username {
  font-weight: bold;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  padding: 10px;
  width: 90vw;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.2s;
}

.search-bar input:focus {
  outline: none;
  border-color: #007bff;
}

.load-more-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.load-more-button:hover {
  background-color: #0056b3;
}

/* Dark mode styles */
body.dark-mode {
  background-color: #121212;
  color: #fff;
}

.ioc-result.dark-mode {
  background-color: #333;
  border: 1px solid #555;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.ioc-header.dark-mode {
  color: #999;
}

.ioc-details.dark-mode {
  color: #ccc;
}

.search-bar.dark-mode input {
  background-color: #333;
  border-color: #555;
  color: #fff;
}