body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .bluecheat-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
  }
  
  h1 {
    color: #007bff;
  }
  
  h2 {
    color: #333;
  }
  
  .cheat-sheet {
    margin-bottom: 20px;
  }
  
  .cheat-sheet-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cheat-sheet-button:hover {
    background-color: #0056b3;
  }
  
  hr {
    border: 0;
    border-top: 1px solid #ddd;
    margin: 20px 0;
  }