/* CybersecurityIOCForm.css */

.exploit9-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
}

.form {
  background-color: rgba(255, 255, 255, 0.8); /* Use a semi-transparent white background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.feedback-buttons {
  display: flex;
  justify-content: space-between;
}

.feedback-buttons button {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.feedback-buttons button.selected {
  background-color: #007bff;
  color: #fff;
}

.form-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333; /* Dark text color for better readability */
}

.form-field {
  margin-bottom: 15px;
}

.form-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333; /* Dark text color for better readability */
}

.form-field select,
.form-field input,
.form-field textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333; /* Dark text color for better readability */
  background-color: rgba(255, 255, 255, 0.95); /* Use a slightly transparent white background */
}

.form-field textarea {
  height: 120px;
}

.steps-container {
  /* Remove max-height property to allow full scrolling */
  overflow-y: auto;
  margin-bottom: 15px;
}

.step-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.step-field textarea {
  flex: 1;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #0056b3;
}