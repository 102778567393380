/* Mentorship Conduct Container */

  .mentorship-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .mentor-section {
    flex: 1;
    max-width: calc(50% - 10px); /* Adjust the width as needed */

    font-family: Arial, sans-serif;
    margin: 20px auto;
    max-width: 800px;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .mentee-section {
    flex: 1;
    max-width: calc(50% - 10px); /* Adjust the width as needed */

    font-family: Arial, sans-serif;
    margin: 20px auto;
    max-width: 800px;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
